<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
<!--          <template v-slot:left>-->
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Suppliers') }}</div>
            <AddNewButton :disabled="!allDataLoaded" :label="$t('Add')" @click="addNewItem" class="p-mr-2"/>
            <Button @click="actualizeSorting" class="p-button-secondary p-button-raised" icon="pi pi-refresh" label="Actualize sorting"></Button>
          </template>
<!--          <template v-slot:right>-->
          <template #end>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else
                          :showHeader="false"
                          :tableName="$t('Suppliers')"
                          :dataTableIsLoading="dataTableIsLoading"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :searchData="searchData"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          @search-items="searchItems"
                          :headers="[
              {name: 'company_name', title: 'Company',sortable: true},
              {name: 'name', title: $t('Name'), sortable: true},
              {name: 'reg_number', title: $t('Reg. number'), sortable: true},
              // {name: 'phone_code', title: 'Phone code', sortable: true},
              {name: 'phone_number', title: $t('Phone'), sortable: true},
              {name: 'email', title: $t('Email'), sortable: true},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td>
                <span v-if="item.company_name">{{ item.company_name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.name">{{ item.name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.reg_number">{{ item.reg_number }}</span>
                <i v-else class="ti-minus"></i>
              </td>
<!--              <td>-->
<!--                <span v-if="item.phone_code">{{ item.phone_code }}</span>-->
<!--              </td>-->
<!--              <td>-->
<!--                <span v-if="item.phone_number">{{ item.phone_number }}</span>-->
<!--              </td>-->
              <td>
                <span v-if="item.phone_code && item.phone_number">+{{ item.phone_code }} {{ item.phone_number }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.email" style="white-space: pre-wrap">{{ item.email }}</span>
                <i v-else class="ti-minus"></i>
              </td>

              <td class="table-date-line">
                <span v-if="item.created_at">
                  {{ formatDate(item.created_at) || '' }},
                </span>
                <span v-if="item.creator">
                  {{ item.creator.first_name }} {{ item.creator.last_name }}
                </span>
              </td>
              <td>
                <div class="p-d-flex">
<!--                  <router-link :to="{ path: `/users/${item.id}` }" target="_blank"><ViewButton/></router-link>-->
<!--                  <EditButton :disabled="item.role === constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.superAdmin"-->
<!--                              @click="editItem(item)" />              -->
                  <EditButton :disabled="!allDataLoaded || dataTableIsLoading"
                              @click="editItem(item)" />
                  <DeleteButton @click="confirmItemDelete(item)"
                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <!--  Mobile DataTable -->
<!--        <MobileDataTable v-else-if="!isLoading && $store.state.tabletLayout"-->
<!--                         :tableName="$t('Suppliers')"-->
<!--                         :showSearchInput="false"-->
<!--                         :itemsArrayIsEmpty="itemsArrayIsEmpty"-->
<!--                         :searchData="searchData"-->
<!--                         @search-items="searchItems">-->
<!--          <template v-slot:body>-->
<!--            <tr :class="{'updated-item': isItemUpdated(item.id)}"-->
<!--                v-for="(item, index) of items"-->
<!--                :key="index">-->
<!--              <td>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center p-pt-2">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Supplier') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <div>-->
<!--                      <span v-if="item.company_name">{{ item.company_name }}</span> <span v-if="item.name">({{ item.name }})</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Reg. number') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ item.reg_number || '' }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Phone') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ item.phone_code || '' }} {{ item.phone_number || '' }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Email') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ item.email || '' }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Created') }}</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <div class="table-date-line">-->
<!--                      <span v-if="item.created_at">{{ formatDate(item.created_at) || '' }}, </span>-->
<!--                      <span v-if="item.creator" class="table-date-line">-->
<!--                        <span v-if="item.creator.is_company && item.creator.company">{{ item.creator.company.name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
<!--                        <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-m-2 p-d-flex p-jc-center">-->
<!--&lt;!&ndash;                  <router-link :to="{ path: `/users/${item.id}` }" target="_blank"><ViewButton/></router-link>&ndash;&gt;-->
<!--                  <EditButton :disabled="(disableEditButton && isItemUpdated(item.id)) || !allDataLoaded" @click="editItem(item)" />-->
<!--                  <DeleteButton @click="confirmItemDelete(item)"-->
<!--                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->
<!--        </MobileDataTable>-->

        <OwnPaginator v-show="items && items.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>


<!--        <IndividualModal-->
<!--            :visible="firstDataModal"-->
<!--            @close="closeFirstDataModal"-->
<!--            @update-items="updateItems"-->
<!--            :item="firstItem">-->
<!--        </IndividualModal>-->
<!--&lt;!&ndash;        :firstLayerOpened="firstLayerOpened"&ndash;&gt;-->
<!--&lt;!&ndash;        :secondLayerOpened="secondLayerOpened"&ndash;&gt;-->
<!--&lt;!&ndash;        @close-first-layer="closeFirstLayer"&ndash;&gt;-->
<!--&lt;!&ndash;        @toggle-second-layer="toggleSecondLayer"&ndash;&gt;-->

        <SupplierModal :visible="itemModal"
                     :item="item"
                     :taxes="taxes"
                     @update-items="updateItems"
                     @close="closeItemModal">
        </SupplierModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import SupplierModal from '@/pages/data_directory/components/SupplierModal'
import constants from '@/constants'
import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import datatableMixins from "@/mixins/datatableMixins";
import settings from "@/settings";

export default {
  mixins: [ httpMixins, formatMixins, datatableMixins ],
  components: { SupplierModal, },
	data() {
		return {
      taxes: null,
      fieldsExpand: 'creator',
      apiLink: 'supplier/get-active-suppliers',
      routePath: '/suppliers',
      sort: {
        column: 'company_name',
        direction: '',
      },

		}
	},
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.getItems()
    this.getTaxes()
    // this.getTaxes().then(() => {
    //   this.taxes = this.taxes?.filter(tax => !tax.is_default) ?? []
    // })
	},
  methods: {
    async actualizeSorting() {
      try {
        const { status } = await httpClient('supplier/set-sorting')
        if (status === 200) {
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife})
        }
      } catch (err) {
        this.showError(err)
      }
    },
    async getItems() {
      this.dataTableIsLoading = true
      this.disableEditButton = true
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient({
          // const { data } = await httpClient({
          url: this.apiLink,
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.disableEditButton = false
        this.dataTableIsLoading = false
      }
      // this.unselectAllItems()

    },
    async deleteItem() {
      console.log('delete supplier')
      // try {
      //   const { status } = await httpClient.post('user/delete-user', {id: this.itemToDelete.id})
      //   if (status === 204) {
      //     this.updatedItemId = null
      //
      //     this.searchData = ''
      //     this.$router.push({
      //       route: this.$route.path,
      //       query: {
      //         ...this.$route.query,
      //         search: '',
      //       }
      //     })
      //
      //     this.getItems()
      //     this.closeConfirmItemDeleteModal()
      //   }
      // } catch(err) {
      //   this.showError(err)
      // }
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.taxes)
    },
    actionButtonsCount() {
      if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
        return 2
      } else {
        return 1
      }
    },
    itemToDeleteData() {
      // if (this.itemToDelete.role === 8) {
      //   return this.itemToDelete.company_name
      // } else {
      //   return (this.itemToDelete.first_name ? (this.itemToDelete.first_name) : '')  + (this.itemToDelete.last_name ? ' ' + this.itemToDelete.last_name : '')
      // }
      return 'delete data'
    },
  }
}
</script>

<style scoped lang="scss">


//@media screen and (max-width: 960px) {
//	::v-deep(.p-datatable) {
//		&.p-datatable-customers {
//			.p-datatable-thead > tr > th,
//			.p-datatable-tfoot > tr > td {
//				display: none !important;
//			}
//
//			.p-datatable-tbody > tr {
//				border-bottom: 1px solid var(--surface-d);
//
//				> td {
//					text-align: left;
//					display: block;
//					border: 0 none !important;
//					width: 100% !important;
//					float: left;
//					clear: left;
//					border: 0 none;
//
//					&:last-child{
//						text-align: center;
//					}
//
//					.p-column-title {
//						padding: .4rem;
//						min-width: 30%;
//						display: inline-block;
//						margin: -.4rem 1rem -.4rem -.4rem;
//						font-weight: bold;
//					}
//
//					.p-rating {
//						display: inline-block;
//					}
//				}
//			}
//		}
//	}
//
//	::v-deep(.p-toolbar) {
//		flex-wrap: wrap;
//
//		.p-button {
//			margin-bottom: .25rem;
//		}
//	}
//}
</style>
